var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navigation-edit" },
    [
      _c(
        "el-form",
        {
          key: "mainForm",
          ref: "mainForm",
          staticClass: "zwx-form navigation-edit",
          staticStyle: { padding: "0 !important" },
          attrs: {
            model: _vm.mainForm,
            rules: _vm.rules,
            "label-position": "right"
          }
        },
        [
          _c("base-headline", {
            staticClass: "headline",
            attrs: { title: "基本信息" }
          }),
          _c(
            "div",
            {
              staticStyle: {
                "max-width": "344px",
                padding: "10px 0 5px 20px !important"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "功能类型：",
                    prop: "moduleCode"
                  }
                },
                [
                  _c("code-select", {
                    ref: "testSimpleMultipleCode",
                    attrs: {
                      codeType: "1018",
                      clearable: false,
                      collapseTags: true,
                      disabled: false
                    },
                    model: {
                      value: _vm.mainForm.moduleCode,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "moduleCode", $$v)
                      },
                      expression: "mainForm.moduleCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "功能描述：",
                    prop: "moduleName"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入",
                      maxlength: "200",
                      "show-word-limit": "",
                      clearable: "",
                      autosize: { minRows: 3, maxRows: 5 }
                    },
                    model: {
                      value: _vm.mainForm.moduleName,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "moduleName", $$v)
                      },
                      expression: "mainForm.moduleName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "菜单标题：",
                    prop: "menuTitle"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      maxlength: "15",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.menuTitle,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "menuTitle", $$v)
                      },
                      expression: "mainForm.menuTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "菜单描述：",
                    prop: "menuDesc"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      maxlength: "15",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.menuDesc,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "menuDesc", $$v)
                      },
                      expression: "mainForm.menuDesc"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "菜单图标：",
                    prop: "menuIcon"
                  }
                },
                [
                  _c(
                    "file-upload",
                    {
                      ref: "menuIconRef",
                      attrs: {
                        accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                        size: 5 * 1024 * 1024,
                        sizeInfo: "5M"
                      },
                      on: {
                        showFileList: _vm.menuIconShowFileList,
                        success: _vm.menuIconUploadSuccess
                      }
                    },
                    [
                      _vm.$zwxBase.verifyIsBlank(_vm.menuIconList)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-26",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-upload2"
                              }
                            },
                            [_vm._v("上传")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$zwxBase.verifyIsNotBlank(_vm.menuIconList)
                    ? _c(
                        "div",
                        { staticClass: "show-file" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              on: {
                                click: function($event) {
                                  return _vm.openMenuIconPreview(
                                    _vm.menuIconList,
                                    0
                                  )
                                }
                              }
                            },
                            [_vm._v("查看")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.deletionMenuIcon(0)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "菜单编号：",
                    prop: "orderNo"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                      maxlength: "9",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.orderNo,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "orderNo", $$v)
                      },
                      expression: "mainForm.orderNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "菜单图片：",
                    prop: "menuPicture"
                  }
                },
                [
                  _c(
                    "file-upload",
                    {
                      ref: "menuPictureRef",
                      attrs: {
                        accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG,",
                        size: 5 * 1024 * 1024,
                        sizeInfo: "5M"
                      },
                      on: {
                        showFileList: _vm.menuPictureShowFileList,
                        success: _vm.menuPictureUploadSuccess
                      }
                    },
                    [
                      _vm.$zwxBase.verifyIsBlank(_vm.menuPictureList)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-icontext-26",
                              attrs: {
                                type: "primary",
                                icon: "el-icon-upload2"
                              }
                            },
                            [_vm._v("上传")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.$zwxBase.verifyIsNotBlank(_vm.menuPictureList)
                    ? _c(
                        "div",
                        { staticClass: "show-file" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              on: {
                                click: function($event) {
                                  return _vm.openMenuPicturePreview(
                                    _vm.menuPictureList,
                                    0
                                  )
                                }
                              }
                            },
                            [_vm._v("查看")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "zwx-button zwx-button-text-26",
                              attrs: { type: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.deletionMenuPicture(0)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "菜单类别：",
                    required: ""
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      model: {
                        value: _vm.mainForm.menuType,
                        callback: function($$v) {
                          _vm.$set(_vm.mainForm, "menuType", $$v)
                        },
                        expression: "mainForm.menuType"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          key: 1,
                          staticClass: "zwx-radio",
                          attrs: { label: 1 }
                        },
                        [_vm._v("内部菜单")]
                      ),
                      _c(
                        "el-radio",
                        {
                          key: 2,
                          staticClass: "zwx-radio",
                          attrs: { label: 2 }
                        },
                        [_vm._v("外部菜单")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "功能层级编码：",
                    prop: "moduleLevelCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      maxlength: "50",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.moduleLevelCode,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "moduleLevelCode", $$v)
                      },
                      expression: "mainForm.moduleLevelCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "menu-code-form",
                  attrs: {
                    "label-width": "84px",
                    label: "菜单URL：",
                    prop: "linkUrl"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      maxlength: "150",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.linkUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "linkUrl", $$v)
                      },
                      expression: "mainForm.linkUrl"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "ms-el-message ms-el-message--warning",
                      attrs: {
                        title:
                          "如果外部菜单需要使用到用户名，需要在地址后面拼接上“?userno”"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-warning" })]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "menu-code-form",
                  attrs: {
                    "label-width": "84px",
                    label: "菜单编码：",
                    prop: "menuCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      maxlength: "50",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    model: {
                      value: _vm.mainForm.menuCode,
                      callback: function($$v) {
                        _vm.$set(_vm.mainForm, "menuCode", $$v)
                      },
                      expression: "mainForm.menuCode"
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "ms-el-message ms-el-message--warning",
                      attrs: {
                        title:
                          "同系统菜单编码，如需定位到头部导航的某个菜单下，使用标记；例如“1_xxx”"
                      }
                    },
                    [_c("i", { staticClass: "el-icon-warning" })]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "84px",
                    label: "状态：",
                    required: ""
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      model: {
                        value: _vm.mainForm.ifEnable,
                        callback: function($$v) {
                          _vm.$set(_vm.mainForm, "ifEnable", $$v)
                        },
                        expression: "mainForm.ifEnable"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("停用")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider", { staticClass: "base-divider" }),
      _c(
        "div",
        { staticStyle: { padding: "12px 20px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-28",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.$system.valid(
                    _vm.$refs["mainForm"],
                    "提示",
                    "确定要提交吗？",
                    "确定",
                    "取消",
                    _vm.commit
                  )
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      ),
      _c("file-preview", { ref: "filePreview" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }