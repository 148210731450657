<template>
  <div class="navigation-edit">
    <el-form class="zwx-form navigation-edit" style="padding: 0 !important;" key="mainForm" ref="mainForm" :model="mainForm" :rules="rules" label-position="right">
      <!-- 基础信息部分 -->
      <base-headline title="基本信息" class="headline" />
      <div style="max-width: 344px; padding: 10px 0 5px 20px !important;">
        <el-form-item label-width="84px" label="功能类型：" prop="moduleCode">
          <code-select ref="testSimpleMultipleCode" codeType="1018" v-model="mainForm.moduleCode" :clearable="false" :collapseTags="true" :disabled="false" />
        </el-form-item>
        <el-form-item label-width="84px" label="功能描述：" prop="moduleName">
          <el-input type="textarea" style="width:500px;" placeholder="请输入" v-model="mainForm.moduleName" maxlength="200" show-word-limit clearable :autosize="{ minRows: 3, maxRows: 5 }" />
        </el-form-item>
        <el-form-item label-width="84px" label="菜单标题：" prop="menuTitle">
          <el-input class="zwx-input" v-model="mainForm.menuTitle" maxlength="15" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label-width="84px" label="菜单描述：" prop="menuDesc">
          <el-input class="zwx-input" v-model="mainForm.menuDesc" maxlength="15" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label-width="84px" label="菜单图标：" prop="menuIcon">
          <file-upload ref="menuIconRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="menuIconShowFileList" @success="menuIconUploadSuccess" :size="5 * 1024 * 1024" sizeInfo="5M">
            <el-button class="zwx-button zwx-button-icontext-26" type="primary" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(menuIconList)">上传</el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(menuIconList)">
            <el-button class="zwx-button zwx-button-text-26" @click="openMenuIconPreview(menuIconList, 0)">查看</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionMenuIcon(0)">删除</el-button>
          </div>
        </el-form-item>
        <el-form-item label-width="84px" label="菜单编号：" prop="orderNo">
          <el-input class="zwx-input" v-model="mainForm.orderNo" oninput="value=value.replace(/[^\d]/g,'')" maxlength="9" placeholder="请输入" clearable />
        </el-form-item>

        <el-form-item label-width="84px" label="菜单图片：" prop="menuPicture">
          <file-upload ref="menuPictureRef" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG," @showFileList="menuPictureShowFileList" @success="menuPictureUploadSuccess" :size="5 * 1024 * 1024" sizeInfo="5M">
            <el-button class="zwx-button zwx-button-icontext-26" type="primary" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(menuPictureList)">上传</el-button>
          </file-upload>
          <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(menuPictureList)">
            <el-button class="zwx-button zwx-button-text-26" @click="openMenuPicturePreview(menuPictureList, 0)">查看</el-button>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionMenuPicture(0)">删除</el-button>
          </div>
        </el-form-item>
        <el-form-item label-width="84px" label="菜单类别：" required>
          <el-radio-group class="zwx-radio-group" v-model="mainForm.menuType">
            <el-radio class="zwx-radio" :label="1" :key="1">内部菜单</el-radio>
            <el-radio class="zwx-radio" :label="2" :key="2">外部菜单</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="84px" label="功能层级编码：" prop="moduleLevelCode">
          <el-input class="zwx-input" v-model="mainForm.moduleLevelCode" maxlength="50" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label-width="84px" label="菜单URL：" prop="linkUrl" class="menu-code-form">
          <el-input class="zwx-input" v-model="mainForm.linkUrl" maxlength="150" placeholder="请输入" clearable />
          <div class="ms-el-message ms-el-message--warning" title="如果外部菜单需要使用到用户名，需要在地址后面拼接上“?userno”"><i class="el-icon-warning"></i></div>
        </el-form-item>
        <el-form-item label-width="84px" label="菜单编码：" prop="menuCode" class="menu-code-form">
          <el-input class="zwx-input" v-model="mainForm.menuCode" maxlength="50" placeholder="请输入" clearable />
          <div class="ms-el-message ms-el-message--warning" title="同系统菜单编码，如需定位到头部导航的某个菜单下，使用标记；例如“1_xxx”"><i class="el-icon-warning"></i></div>
        </el-form-item>
        <el-form-item label-width="84px" label="状态：" required>
          <el-radio-group class="zwx-radio-group" v-model="mainForm.ifEnable">
            <el-radio class="zwx-radio" :label="true">启用</el-radio>
            <el-radio class="zwx-radio" :label="false">停用</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>
    <el-divider class="base-divider" />
    <!-- 提交部分 -->
    <div style="padding: 12px 20px;">
      <el-button class="zwx-button zwx-button-28" type="primary" @click="$system.valid($refs['mainForm'], '提示', '确定要提交吗？', '确定', '取消', commit)">提交</el-button>
    </div>

    <!--文件预览弹框-->
    <file-preview ref="filePreview" />
  </div>
</template>

<script>
export default {
  name: 'MenuEdit',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      rid: this.$route.params.rid,
      mainForm: {
        moduleCode: '',
        moduleName: '',
        menuTitle: '',
        menuDesc: '',
        menuIcon: '',
        menuPicture: '',
        linkUrl: '',
        menuType: 1,
        ifEnable: true,
      },
      menuPictureList: [], //菜单图片
      menuIconList: [], //菜单图标
      menuIconAnnex: {
        filePath: '',
        fileName: '附件',
      },
      menuPictureAnnex: {
        filePath: '',
        fileName: '附件',
      },
      folder: 'repository/navigation',
      rules: {
        moduleCode: [{ required: true, message: '请选择功能编码', trigger: ['change', 'blur'] }],
        moduleName: [{ required: true, message: '请输入功能描述', trigger: ['change', 'blur'] }],
        menuDesc: [{ required: true, message: '请输入菜单描述', trigger: ['change', 'blur'] }],
        menuTitle: [{ required: true, message: '请输入菜单标题', trigger: ['change', 'blur'] }],
        linkUrl: [{ required: true, pattern: /(^(http|https):\/\/\S)|^\/\S/, message: '请输入以http://或https://或/开头的菜单URL', trigger: ['change', 'blur'] }],
        menuIcon: [{ required: true, message: '请上传菜单图标', trigger: ['change', 'blur'] }],
        menuPicture: [{ required: true, message: '请上传菜单图片', trigger: ['change', 'blur'] }],
      },
    }
  },
  created() {},
  mounted() {
    this.detail()
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  methods: {
    detail() {
      if (this.rid === undefined) return
      this.$emit('loading', true)
      let data = {
        rid: this.rid,
      }
      this.$system.get(this.api + '/systematic/getNavigation-1', data, true, true, this.detailSuccess, this.detailError)
    },
    detailSuccess(data) {
      if (data.type === '00') {
        this.mainForm = data.tdModuleNavigation
        this.menuPictureAnnex.filePath = this.mainForm.menuPicture
        this.menuIconAnnex.filePath = this.mainForm.menuIcon
        this.menuIconList.push(this.menuIconAnnex)
        this.menuPictureList.push(this.menuPictureAnnex)
        this.$emit('loading', false)
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
        this.back()
      }
    },
    detailError() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
      this.back()
    },
    commit() {
      this.$emit('loading', true)
      let data = { ...this.mainForm }
      data.rid = this.rid
      this.$system.postJson(this.api + '/systematic/addOrModifyNavigation-1', data, true, true, this.commitSuccess, this.commitError)
    },
    commitSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.$router.replace({ name: this.breadcrumbs[this.breadcrumbs.length - 2].name, params: { fresh: true } })
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    commitError() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
    back() {
      this.$router.replace({ name: this.breadcrumbs[this.breadcrumbs.length - 2].name })
    },

    /**
     * 菜单图标上传显示回调
     */
    menuIconShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.menuIconList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.menuIconList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid(0)
      }
    },
    /**
     * 菜单图片上传显示回调
     */
    menuPictureShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.menuPictureList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.menuPictureList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid(1)
      }
    },
    // 获取图片的UID
    generateFilesUid(type) {
      this.$emit('loading', true)
      let data = {
        folder: this.folder,
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-0', data, false, false, data => {
        if (data.type === '00') {
          if (type == 0) {
            this.$refs.menuIconRef.submit(data.annexUploadUid)
          } else {
            this.$refs.menuPictureRef.submit(data.annexUploadUid)
          }

          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    /**
     * 菜单图标上传成功回调
     */
    menuIconUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.menuIconList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.menuIconList) {
          for (let j in fileList) {
            if (this.menuIconList[i].fileName === fileList[j].fileName) {
              this.menuIconList[i].filePath = fileList[j].filePath
              this.mainForm.menuIcon = this.$zwxBase.verifyIsNotBlank(this.menuIconList) ? this.menuIconList[0].filePath : null
            }
          }
        }
      }
    },
    /**
     * 菜单图片上传成功回调
     */
    menuPictureUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.menuPictureList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.menuPictureList) {
          for (let j in fileList) {
            if (this.menuPictureList[i].fileName === fileList[j].fileName) {
              this.menuPictureList[i].filePath = fileList[j].filePath
              this.mainForm.menuPicture = this.$zwxBase.verifyIsNotBlank(this.menuPictureList) ? this.menuPictureList[0].filePath : null
            }
          }
        }
      }
    },
    /**
     * 删除营业执照文件
     */
    deletionMenuIcon(index) {
      let fileName = this.menuIconList[index].fileName
      let filePath = this.menuIconList[index].filePath
      this.menuIconList.splice(index, 1)
      this.mainForm.menuIcon = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.menuIconRef.deleteIndex(index)
      }
      let fileList = this.$refs.menuIconRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.menuIconRef.deleteIndex(j)
            fileList = this.$refs.menuIconRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 删除营业执照文件
     */
    deletionMenuPicture(index) {
      let fileName = this.menuPictureList[index].fileName
      let filePath = this.menuPictureList[index].filePath
      this.menuPictureList.splice(index, 1)
      this.mainForm.menuPicture = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.menuPictureRef.deleteIndex(index)
      }
      let fileList = this.$refs.menuPictureRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.menuPictureRef.deleteIndex(j)
            fileList = this.$refs.menuPictureRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 菜单图标预览
     */
    openMenuIconPreview(fileList, index) {
      // 直接调用文件预览组件的方法
      this.$refs.filePreview.initPreviewFile(fileList, index)
    },

    /**
     * 菜单图片预览
     */
    openMenuPicturePreview(fileList, index) {
      // 直接调用文件预览组件的方法
      this.$refs.filePreview.initPreviewFile(fileList, index)
    },
  },
}
</script>

<style lang="less" scoped>
.zwx-form .el-form-item {
  margin-bottom: 20px !important;
}

/deep/ .zwx-headline .zwx-head-title {
  border-top: 0px !important;
}
.navigation-edit /deep/ .el-input__inner {
  width: 260px !important;
}

.navigation-edit /deep/ .zwx-input {
  width: 260px !important;
}
.menu-code-form /deep/ .el-form-item__content {
  display: flex;
}
.ms-el-message {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
  border-radius: 2px;
}

.ms-el-message--warning {
  color: #ff9400;
}
</style>
